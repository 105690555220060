

<template>
  <label-cmpt
    :showTitle="'职工标签-文章标签'"
    :showRefreshDataCmp="false"
    :taskType="'LabelRead'"
    :midType="'userLabel'"
    :labelType="'user'"
    :columnTitle="'userLabelName'"
    :showFilter="false"
    :selfColumn="selfColumn"
  ></label-cmpt>
</template>

<script>
// 哪些人为标签爱看哪些文章的标签
// @ is an alias to /src
import _ from "lodash";

import labelCmpt from "./labelCmpt.vue";

export default {
  name: "userLabelDeptStats",
  components: {
    labelCmpt,
  },

  data() {
    return {
      selfColumn: [
        // { name: "用户标签", value: "userLabelName" },

        {
          name: "阅读数量",
          value: "count",
        },

        {
          name: "点赞数量",
          value: "like",
        },
        {
          name: "分享数量",
          value: "share",
        },
        {
          name: "评论数量",
          value: "comment",
        },
        { name: "喜好标签", value: "contentLabelName", selfValLine: true },
      ],
    };
  },
  methods: {},

  destroyed() {},
  async created() {},
};
</script>

<style scoped>
</style>
